import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
// import Search from "@/feature-reskin-probuilds-net/components/Search.jsx";
import { FeedSection } from "@/feature-reskin-probuilds-net/Home.style.jsx";
import { getProMatchlistQueryString } from "@/game-lol/utils/probuilds-utils.mjs";
import ContentfulArticleGrid from "@/marketing/ContentfulArticleGrid.jsx";
import ContentfulArticles from "@/marketing/ContentfulArticles.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Home() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader
        image={`${appURLs.CDN}/probuildsnet/logomini.webp`}
        imageAlt="Pro Builds abbreviated"
        title={t("probuilds:title.proBuilds", "Pro Builds")}
      />
      <hr style={{ margin: "var(--sp-4) 0" }} className="invis" />
      <ContentfulArticleGrid
        type="contentful-grid"
        linkConstructor={(article) => `/article/${article.id}`}
        title={["contentful:latestGuides.title", "Latest Guides"]}
        description={[
          "contentful:latestGuides.description",
          "Stay up to date with the latest guides!",
        ]}
      />
      <div style={{ margin: "var(--sp-4) 0" }} className="w-full">
        <ContentfulArticles
          type="contentful-articles"
          linkConstructor={(article) => `/article/${article.id}`}
          cta={{
            title: ["contentful:latestGuides.cta", "View Latest Guides"],
            href: "/guides",
          }}
        />
      </div>
      <Container>
        <Feed />
      </Container>
    </Layout>
  );
}

function Feed() {
  const {
    lol: { proBuildMatchlist },
  } = useSnapshot(readState);

  const queryString = getProMatchlistQueryString(null, null);
  const matchlist = proBuildMatchlist[queryString];

  return (
    <FeedSection>
      <Matchlist matches={matchlist} currRole={null} />
    </FeedSection>
  );
}

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds offers pro League of Legends champion stats, guides, builds, runes, masteries & counters by role, including win, ban & play rates.",
    ],
    subtitle: true,
  };
}
